<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div v-else class="row">
        <div class="col-md-4">
          <agency-detail :data="agency_ladies"></agency-detail>
        </div>
        <div class="col-md-8">
          <form-agency></form-agency>
        </div>
        <div class="col-md-12">
          <agency-table :data="agency_fees"></agency-table>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { apiGet } from "../../services/api";
import AgencyDetail from "./AgencyDetail.vue";
import FormAgency from "./FormAgencyFee.vue";
import AgencyTable from "./AgencyTable.vue";

export default {
  name: "AgencyLadies",
  components: { AgencyDetail, FormAgency, AgencyTable },
  data() {
    return {
      name: "Agency Ladies",
      agency_ladies: [],
      agency_fees: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
    this.getAgencyFee();
  },
  methods: {
    getData() {
      this.loading = true;
      let id = this.$route.params.id;
      apiGet("agency_ladies/" + id).then((result) => {
        this.agency_ladies = result.data.data;
        this.loading = false;
      });
    },
    getAgencyFee() {
      apiGet("agency_ladies_fee", {
        agency_ladies_id: this.$route.params.id,
      }).then((result) => {
        this.agency_fees = result.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
