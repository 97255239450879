<template>
  <div class="card">
    <div class="card-header">
      <h4>Detail Ladies</h4>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label>Name</label>
        <h5>{{ data.name }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>
